<template>
  <div id="foreignOutbounds">
    <header-nav :content="`${$t('pages_router_007')} / ${ $t('pages_pay_110')}`" />
    <div class="common-card-body">
      <div class="inbounds-header">
        <div class="main-title">{{ $t('pages_pay_001') }}</div>
        <div class="sub-title">{{ $t('pages_pay_002') }}</div>
      </div>
      <div class="inbounds-body">
        <img style="width: 383px" src="~@/assets/images/foreign-outbounds.png" class="main-img">
        <div class="flex-align inbounds-btn">
          <a-button
            shape="round"
            type="primary"
            class="single-btn"
            @click="singleApply"
          >{{ $t('pages_pay_003') }}</a-button>
          <a-button
            shape="round"
            type="primary"
            class="batch-btn"
            @click="$router.push('/funds/outbounds/batch_apply')"
          >{{ $t('pages_pay_004') }}</a-button>
        </div>
        <div style="display: flex;  justify-content: center">
          <div class="payee-choose" :style="{visibility: display ? 'visible' : 'hidden'}">
            <h3>{{ $t('pages_pay_005') }}</h3>
            <div
              v-for="btn in chooseBtns"
              :key="btn.key"
              class="choose-btn"
              :class="activeKey === btn.key ? 'active' : ''"
              @click="chooseBtnClick(btn)"
            >
              <img src="~@/assets/icons/ic-user.png">
              <div class="desc-info">
                <div class="title">{{ btn.title }}</div>
                <div class="desc">{{ btn.desc }}</div>
              </div>
              <down-outlined v-show="btn.selected && !selected" class="ic-select" />
              <up-outlined v-show="btn.selected && selected" class="ic-select" />
            </div>
            <div v-show="selected" class="choose-area">
              <person-select
                ref="selectRef"
                menu-length="688px"
                method-type="outbound"
                @select="menuSelect"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderNav from '@/components/header-nav'
import PersonSelect from '@/components/person-select'
import { ref, reactive, toRefs, createVNode, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { Modal, message } from 'ant-design-vue'
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import i18n from '@/locale'
import { _pay } from '@/api'
export default {
  name: 'ForeignOutbounds',
  components: {
    'header-nav': HeaderNav,
    'person-select': PersonSelect
  },
  setup () {
    let timer = null
    const selectRef = ref(null)
    const router = useRouter()
    const state = reactive({
      display: false,
      selected: false,
      activeKey: '',
      payees: [],
      chooseBtns: [
        { key: 'create', title: i18n.global.t('pages_pay_006'), desc: i18n.global.t('pages_pay_008'), selected: false },
        { key: 'choose', title: i18n.global.t('pages_pay_007'), desc: i18n.global.t('pages_pay_009'), selected: true }
      ]
    })

    onUnmounted(() => {
      clearInterval(timer)
    })

    const singleApply = () => {
      state.display = true
    }

    const chooseBtnClick = (btn) => {
      state.activeKey = btn.key
      if (btn.selected) state.selected = !state.selected
      else router.push('/funds/outbounds/payee_edit')
    }

    const menuSelect = async (payeeId) => {
      clearInterval(timer)
      timer = setInterval(() => {
        clearInterval(timer)
        chooseContinue(payeeId)
      }, 500)
    }

    const chooseContinue = async (payeeId) => {
      const res = await _pay.outboundPayeeCheck({ payeeId })
      if (res.data.succ) {
        if (res.data.data) {
          Modal.confirm({
            title: i18n.global.t('common_text_037'),
            icon: createVNode(InfoCircleOutlined),
            content: createVNode('div', {}, [
              createVNode('div', {}, i18n.global.t('pages_pay_331')),
              createVNode('div', { style: { marginTop: '5px' }}, i18n.global.t('pages_pay_332')),
              createVNode('div', { style: { marginTop: '5px' }}, i18n.global.t('pages_pay_333'))
            ]),
            width: 468,
            okText: i18n.global.t('pages_pay_334'),
            onOk() {
              router.push('/funds/payee_manage')
            }
          })
        } else {
          state.selected = false
          router.push({ path: '/funds/outbounds/single_apply', query: { payeeId }})
        }
      } else {
        message.error(res.data.resMsg)
      }
    }

    const loadPayeeList = async () => {
      const res = await _pay.outboundPayeeNoPageList({ status: '01' })
      if (res.data.succ) {
        state.payees = res.data.data.map(item => {
          return Object.assign({}, item, {
            code: String(item.payeeId),
            name: item.accountName,
            currency: item.accountCurrency,
            branchName: item.bankName || '-',
            bankNum: item.accountNo
          })
        })
        selectRef.value.initData('', state.payees)
      }
    }

    loadPayeeList()

    return {
      selectRef,
      menuSelect,
      singleApply,
      chooseBtnClick,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../styles/pay-page.less';
</style>
